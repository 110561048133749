import { template as template_b14d53c00499414395f0c15af09230c6 } from "@ember/template-compiler";
const FKText = template_b14d53c00499414395f0c15af09230c6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
