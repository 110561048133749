import { template as template_7e12682a6006485398b038825c7d2cdc } from "@ember/template-compiler";
const FKControlMenuContainer = template_7e12682a6006485398b038825c7d2cdc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
